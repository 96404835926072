.bannerBackgroundCls {
    background: linear-gradient(90deg, rgba(2, 1, 19, 0.7) -2.32%, rgba(2, 1, 19, 0) 110%);
    width: 53% !important;
}

.gridcls {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.gridContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.bannerBackgroundForArabicCls {
  background: linear-gradient(90deg, rgba(2, 1, 19, 0.7) -2.32%, rgba(2, 1, 19, 0) 110%);
  width: 100% !important;
}
.App {
  height: 100vh;
  width: 100vw;
}
.map-container {
  height: 100%;
  width: 100%;
}
.subCategory_shadow {
  -webkit-box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.33);
-moz-box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.33);
box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.33);
}
.vendor-box_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}