.cardDesign{
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
}

.newcardDesign{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}