.buttonactive {
    margin: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #E8E8E8;
    padding: 8px 12px;
    border-radius: 9999px;
    cursor: pointer;
    width: fit-content;
    color: #737373;
    font-size: 13px;
    font-family: "Roboto-Medium";
}

.active {
    background-color: #1F2533;
    margin: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 9999px;
    cursor: pointer;
    width: fit-content;
    color: #fff;
    font-size: 13px;
    font-family: "Roboto-Medium";
}

.gridAlign {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-flow: column;
}
/* {`grid grid-rows-2 grid-flow-col gap-2`} */