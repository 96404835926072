/* textField font size  */
.textMain .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;   
}

/* textField height  */
.textMain .textField1 .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 10px;
}

/* checkBox padding  */
.textMain .paddingLessCheckbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    padding: 0;
}