.searchIcon {
    display: none;
}

.hamBurger {
    display: none;
}

.rightHeader {
    display: flex;
    gap: 1.25rem;
    height: 100%;
    align-items: center;
    position: relative;
}

.notificationResponse {
    display: none;
}

.searchResponse {
      display: none;
  }

  .searchBarTab{
    display: none;
  }

@media screen and (max-width:988px) {
    .searchField {
        display: none;
    }

    .searchIcon {
        display: flex;
        height: 100%;
        align-items: center;
        padding-top: 0.5em;
        position: relative;
    }
}

@media screen and (max-width:563px) {
    .searchBarTab{
        width: 100%;
        display: block;
}
.searchResponse{
    display: none;
}
}

@media screen and (max-width:768px) {
    .notificationResponse {
        display: flex;
        padding-top: 0.5em;
        margin-right: 2em;
    }
}

@media screen and (max-width:1162px) {
    .headerSpace {
        padding-right: 1rem;
    }
}

@media screen and (max-width:768px) {
    .rightHeader {
        display: none;
    }

    .hamBurger {
        display: flex;
        padding-top: 0.5em;
    }
}

@media screen and (min-width:563px) and (max-width:988px) {
    .searchResponse {
        display: block;
        position: absolute;
        width: 100%;
        top: 5em;
    }
    .searchBarTab{
        display: none;
    }
}

  