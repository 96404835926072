.leftbordercls {
    border-radius: 18px 18px 18px 0px;
    font-family: "Roboto-Regular";
    background-color: #FFEFE6;
    color: #434343;
    font-size: 12px;
    padding: 2%;
    display: flex;
    word-break: break-all;
    width: fit-content;
    max-width: 20rem;
}

.rightbordercls {
    border-radius: 18px 18px 0px 18px;
    font-family: "Roboto-Regular";
    background-color: #FFEFE6;
    color: #434343;
    font-size: 12px;
    padding: 2%;
    display: flex;
    word-break: break-all;
    width: fit-content;
    max-width: 20rem;
}