@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


@font-face {
  /* 900  */
  font-family: "Roboto-Black";
  src: url("./Fonts/Roboto/Roboto-Black.ttf");
}

@font-face {
  /* 700  */
  font-family: "Roboto-Bold";
  src: url("./Fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  /* 300  */
  font-family: "Roboto-Light";
  src: url("./Fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
  /* 500  */
  font-family: "Roboto-Medium";
  src: url("./Fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  /* 400  */
  font-family: "Roboto-Regular";
  src: url("./Fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  /* 100  */
  font-family: "Roboto-Thin";
  src: url("./Fonts/Roboto/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./Fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./Fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./Fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./Fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Fonts/Poppins/Poppins-SemiBold.ttf");
}

.landingSearchBar {
  padding: 0 !important;
  margin: 0 !important;
}

.landingSearchBar div {
  height: 2rem;
  width: 100%;
  padding-left: 1em;
}

.landingSearchBar div>input {
  padding: 8px;
}

.landingSearchBar div>fieldset {
  border-color: white !important;
  border-width: 0 !important;
}


.browsingSearchBar {
  padding: 0 !important;
  margin: 0 !important;
}

.browsingSearchBar div {
  height: 3em;
  width: 24.6em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 30px 0 0 30px;
}

.browsingSearchBar div>input {
  padding: 8px;
}

.browsingSearchBar div>fieldset {
  border-color: white !important;
  border-width: 0 !important;
}

.landingLocationSearchBar div {
  width: 100% !important;
}

.landingLocationSearchBar div>input {
  padding: 8px;
}

.landingLocationSearchBar div>fieldset {
  border-color: white !important;
  border-width: 0 !important;
}

.scrollArrow div:nth-child(1) svg {
  color: #3677B1 !important;
}

.scrollArrow div:nth-child(4) {
  color: #3677B1 !important;
  width: 10.5rem !important;
  justify-content: end !important;
}

.textfieldinput .MuiOutlinedInput-input {
  padding: 12px 12px !important;
  font-size: 14px;
  border: none;
  border-radius: 4px;
}

.textfieldinput .MuiOutlinedInput-input:focus {
  outline: none;
  border-bottom: 1px solid red;
}

.checkbox .MuiCheckbox-root {
  padding: 0 !important;
}

.signUpEmailExtracls div>div {
  padding: 0 !important;
}

.signUpPhoneExtracls div>div {
  padding: 0 !important;
}

.selectLocationCls div>div>div>button {
  display: none !important;
}

.selectLocationCls div>div {
  padding: 9px !important;
}

.selectLocationCls div>label {
  margin-top: 3px !important;
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
  color: #a2a2a2;
}

.governorateCls div {
  padding: 12px !important;
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
  /* color: #a2a2a2; */
}

.carousel .MuiPaper-root img {
  border-radius: 4px;
}


.colorforallreqtab .Mui-selected {
  color: #27216E !important;
}

.colorforallreqtab .MuiTabs-indicator {
  background-color: #27216E;
  height: 3px;
}


.colorformyreqtab .Mui-selected {
  color: #27216E !important;
}

.colorformyreqtab .MuiTabs-indicator {
  background-color: #27216E;
  height: 3px;
}


.tabcomponent .MuiTabs-indicator {
  background-color: black;
  height: 3px;
}

.tabcomponent .Mui-selected {
  color: black !important;
}

.tabcomponent .MuiTab-root {
  color: #1F2533;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto-Medium";
}

.tabcomponent .MuiTabPanel-root {
  padding: 10px 0 0 0;
}

.faqtab .MuiTabs-indicator {
  background-color: #5C54B1;
  height: 3px;
}

.faqtab .Mui-selected {
  color: black !important;
}

.faqtab .MuiTab-root {
  color: #1F2533;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto-Medium";
}

.faqtab .MuiTabPanel-root {
  padding: 10px 0 0 0;
}

.addofferform .MuiOutlinedInput-input {
  padding: 10px;
  font-size: 14px;
}

.addofferform .datepickerfield .MuiOutlinedInput-input {
  padding: 12px;
}

.addofferform .datepickerfield .MuiInputLabel-root {
  font-size: 14px;
  margin-bottom: 10px;
}

.addofferform .datepickerfield .MuiOutlinedInput-root {
  padding-top: 4px !important;
}

.textfieldredesign .MuiOutlinedInput-notchedOutline {
  border: none;
}

.textfieldredesign .MuiTextField-root {
  margin: 4px 0px;
}

/* width */
::-webkit-scrollbar {
  height: 1px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  /* margin-left: 50px; */

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;

}

.cssformessageSection .MuiAccordionSummary-content .Mui-expanded {
  margin: 12px 0 !important;
}

.cssformessageSection .MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.MuiList-root {
  padding-bottom: 0 !important;
}

.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation24 .MuiDialog-paper .MuiDialog-paperScrollPaper .MuiDialog-paperWidthSm .MuiPaper-root-MuiDialog-paper {
  width: 100vw !important;
  max-width: 0px !important;
}

.createaddress .MuiInputLabel-root {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
  color: #bcbcbc;
}

/*404 page */
.man404{
	width: 100%;
	height:100vh;
}
.man404Img{
	width: 100%;
	height: 100%;
}
.man404Text{
	text-transform: capitalize;
	position:absolute;
	bottom:35%;
	left:15%
}
.man404TextOOPS{
	font-size: 130px;
	font-weight: 300;
	font-family:  system-ui;
	color: rgb(63, 63, 63);
}
.man404TextNot{
	font-size: 40px;
	font-family: system-ui;
	font-weight: 300;
	color: rgb(123, 123, 123);
}
/*404 page styles end*/

/* decline modal width increased  */
.decline-modal_width .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  width: 38rem !important;
  padding: 15px;
}
.scrollArrowArabic div:nth-child(1) svg {
  color: #3677B1 !important;
}

.scrollArrowArabic div:nth-child(4) {
  color: #3677B1 !important;
  width: 10.5rem !important;
  justify-content: end !important;
}
.textFieldWidth .MuiFormControl-root{
  width: 100%!important;
}

.businessTime .MuiFormLabel-root{
  left: 100px;
}

/* time picker width  */
.timePickerCls .MuiFormControl-root{
  width: 100% !important;
}
.userSignupFormCls div>div>input {
  padding: 14px 14px !important;
}

/*buttonFix*/

.buttonFix{
  padding-left: 0 !important;
}

.userEditTextfield .MuiTextField-root{
  width: 100%;
}
.gridFix{
  flex-wrap: nowrap !important;
}

.tabFix .MuiTabs-indicator{
  display:none;
}
.scrollbarRemover{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbarRemover::-webkit-scrollbar {
  display: none;
}

/* .demo .ql-container .ql-snow{
  height: 10rem;
} */
.headingWrapper div .ql-container{
  height: 8rem;
  font-size: 14px;
  background: white;
}
.contentWrapper div .ql-container{
  height: 12rem;
  max-height: 15rem;
  overflow-y: scroll;
  font-size: 14px;
}

.faqAdmin .MuiAccordionDetails-root{
  width: 100%;
}
/* to give gap between search icon and input text  */
.searchIconGap div>div{
  gap: 10px;
  color: white;
}
.searchIconGapDark div>div{
  gap: 10px;
  color: black;
}

.searchBarfix .MuiFormControl-root{
  width:20rem;
}

.subscriptionCard .MuiTabs-scroller {
  white-space: unset !important;
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev {
  width: 30px;
  height: 30px;
}

.custom-swiper .swiper-button-next {
  right: -7px;
}

.custom-swiper .swiper-button-prev {
  left: -7px;
}

.custom-swiper .swiper-button-next::after,
.custom-swiper .swiper-button-prev::after {
  font-size: 18px;
}

