.stepperWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(242, 242, 242, 0.5);
    width: 33.3333%;
    padding-top: 4.5%;
    }

    @media screen and (max-width:640px) {
        .stepperWrapper{
            width: 100%; 
        }
    }