.category_underline {
  position: relative;
  display: flex;
  gap: 4px;
  /* align-items: center; */
}

.category_underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  top: 4.2rem;
  background-color: #FF5C00;
  color: #FF5C00;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.subCat_underline {
  position: relative;
  display: flex;
  gap: 4px;
  /* align-items: center; */
}

.subCat_underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  top: 3rem;
  background-color: #FF5C00;
  color: #FF5C00;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.scrollbarRemover .vendor_name {
  padding-top: 4px;
}

@media (hover: hover) and (pointer: fine) {
  .category_underline::before {
    left: 0;
    right: auto;
    width: 100%;
  }

  .subCat_underline::before {
    left: 0;
    right: auto;
    width: 100%;
  }
}