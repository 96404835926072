.containerResponsive{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 3em;
}

.BabyCareShowImg{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.businessDetails{
    width: 40%;
}

.customerReviewWidth{
    width: 35%;
}

.reviewsWidth{
    width: 60%;
}

.reviewSection{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

@media screen and (max-width: 1280px) {
    .businessDetails {
        width: 46%;
        
    }
    .customerReviewWidth{
        width: 46%;
    }

    .reviewsWidth{
        width: 50%;
    }
}

@media screen and (max-width: 900px) {
    .reviewSection {
        display: flex !important;
        flex-direction: column !important;
        
    }
    .BabyCareShowImg{
        width: 40%;
    }
    .businessDetails {
        width: 55%;
        
    }
    .containerResponsive{
        gap: 2em;
    }
}
@media screen and (max-width: 900px){
    .customerReviewWidth{
        width: 100%;
    }

    .reviewsWidth{
        width: 100%;
    }
}
@media screen and (max-width: 741px) {

    .containerResponsive{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .BabyCareShowImg{
        width: 100%;
    }

    .businessDetails{
            width: 100%;
    }

}

