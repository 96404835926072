.resizeGrid{
    grid-template-columns: 3fr 1fr !important;
}
.textFieldText .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;   
}

.textFieldHeight .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 10px;
}
.locationFieldSize .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
 padding:  9.5px 11px;
}
.textFieldHeightforCategory .MuiOutlinedInput-input {
  height: 18px !important;
}
/* to remove updown arrow  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* for firefox */
input[type=number] {
  -moz-appearance: textfield;
}
    
/* .dropdownText .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: black;
} */

.gridContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.hyperlinkImg{
  width: 150px;
  height: 150px;
}

/* @media screen and (min-width: 801px) {
  .gridContainer {
display: grid;
  grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .gridContainer {
display: grid;
  grid-template-columns: repeat(1, 1fr);
  }
} */