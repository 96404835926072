.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  width: 31px;
  height: 16px;
  top: 0px;
  bottom: 2;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(14px);
  background-color: #fff;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #31ADD6;
}
.filterIconCls {
  position: absolute;
  bottom: 25%;
  left: 10%;
  float: left;
}
.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 40px;
}


.container {
	transform: translate(-50%);
	margin-right: 20px;
}

.searchBox input[type=text] {
	border: none;
	background: none;
	z-index: 1;
	width: 25px;
	height: 25px;
	transition: all 0.25s ease-in 0.25s;
	color: transparent;
	font-size: 15px;
	font-family: "InterLight";
}

.searchBox input[type=text]:hover {
	cursor: pointer;
}

.searchBox input[type=text]:hover:focus {
	cursor: text;
}

.searchBox input[type=text]:hover+span {
	background: rgba(255, 255, 255, 0.2);
}

.searchBox input[type=text]:focus {
	width: 140px;
	padding: 0 10px;
	outline: none;
	color: #838383;
	background: none;
	color: #838383;
	height: 40px;
}

.searchBox input[type=text]:focus+span {
	width: 200px;
	height: 40px;
}

.searchBox input[type=text]:focus+span::before {
	width: 2px;
	opacity: 0;
	transition: all 0.25s ease-in;
}

.searchBox input[type=text]+span {
	z-index: -1;
	position: absolute;
	border: 2px solid #838383;
	top: 0;
	width: 20px;
	height: 20px;
	transition: all 0.25s ease-in 0.25s;
	border-radius: 25px;
	left: 0;
}

.searchBox input[type=text]+span::before {
	transition: all 0.25s ease-in 0.5s;
	transform-origin: left top;
	content: "";
	position: absolute;
	width: 10px;
	height: 3px;
	border-radius: 5px;
	background: #838383;
	transform: rotate(45deg) translate(26px, -2px);
	top: -3px;
	left: -4px;
}

.searchbar .MuiOutlinedInput-notchedOutline{
	position: unset !important;
	border: unset !important;
}

.pulse:hover,
.pulse:focus {
	-webkit-animation: pulse 1s;
	animation: pulse 1s;
	box-shadow: 0 0 0 1em transparent;
}

@-webkit-keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--hover);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--hover);
	}
}
.pulse {
	--color: #2186D7;
	--hover: #4b8ec4;
  }
  